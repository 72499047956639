import VueRouter from 'vue-router';

import Vue from 'vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home/index.vue'),
    meta: { title: '主页' },
  },
  {
    path: '/newscenter',
    name: 'newscenter',
    redirect: '/newscenter/companynews',
    component: () => import('../views/NewsCenter/index.vue'),
    meta: { title: '资讯中心' },
    children: [
      {
        path: 'companynews/:id?',
        name: 'Companynews',
        component: () => import('../views/NewsCenter/companynews/index.vue'),
        meta: { title: '公司新闻' },
      },
      {
        path: 'notices/:id?',
        name: 'Notices',
        component: () => import('../views/NewsCenter/notices/index.vue'),
        meta: { title: '通知公告' },
      },
    ],
  },
  {
    path: '/search/:id?',
    name: 'Search',
    component: () => import('../components/Search/index.vue'),
    meta: { title: '搜索' },
  },
  {
    path: '/service',
    name: 'service',
    redirect: '/service/npa',
    component: () => import('../views/Service/index.vue'),
    meta: { title: '业务介绍' },
    children: [
      {
        path: 'consult',
        name: 'Consult',
        component: () => import('../views/Service/Consulting/index.vue'),
      },
      {
        path: 'financial',
        name: 'Financial',
        component: () => import('../views/Service/Financial/index.vue'),
      },
      {
        path: 'investment',
        name: 'Investment',
        component: () => import('../views/Service/Investment/index.vue'),
      },
      {
        path: 'npa',
        name: 'NPA',
        component: () => import('../views/Service/NPA/index.vue'),
      },
      {
        path: 'others',
        name: 'Others',
        component: () => import('../views/Service/Others/index.vue'),
      },
      {
        path: 'partner',
        name: 'Partner',
        component: () => import('../views/Service/Partnership/index.vue'),
      },
      {
        path: 'restructuring',
        name: 'Restructuring',
        component: () => import('../views/Service/Restructuring/index.vue'),
      },
    ],
  },
  {
    path: '/party',
    name: 'party',
    redirect: '/party/partywork',
    component: () => import('../views/Party/index.vue'),
    meta: { title: '企业风采' },
    children: [
      {
        path: 'groupwork/:id?',
        name: 'Groupwork',
        component: () => import('../views/Party/Groupwork/index.vue'),
        meta: { title: '工会活动' },
      },
      {
        path: 'partywork/:id?',
        name: 'Partywork',
        component: () => import('../views/Party/Partywork/index.vue'),
        meta: { title: '党建工作' },
      },
    ],
  },
  {
    path: '/jobs',
    name: 'jobs',
    redirect: '/jobs/recruitment',
    component: () => import('../views/Jobs/index.vue'),
    meta: { title: '招贤纳士' },
    children: [
      {
        path: 'recruitment/:id?',
        name: 'Recruitment',
        component: () => import('../views/Jobs/Recruitment/index.vue'),
        meta: { title: '招聘公告' },
      },
      {
        path: 'talent',
        name: 'Talent',
        component: () => import('../views/Jobs/Talent/index.vue'),
        meta: { title: '人才队伍' },
      },
    ],
  },
  {
    path: '/available/:id?',
    name: 'Available',
    component: () => import('../views/Available/index.vue'),
    meta: { title: '资产推介' },
  },
  {
    path: '/aboutus',
    name: 'Aboutus',
    redirect: '/aboutus/company',
    component: () => import('../views/AboutUs/index.vue'),
    meta: { title: '关于我们' },
    children: [
      {
        path: 'company',
        name: 'Company',
        component: () => import('../views/AboutUs/company/index.vue'),
        meta: { title: '公司简介' },
      },
      {
        path: 'culture',
        name: 'Culture',
        component: () => import('../views/AboutUs/culture/index.vue'),
        meta: { title: '文化理念' },
      },
      {
        path: 'connect',
        name: 'Connect',
        component: () => import('../views/AboutUs/connect/index.vue'),
        meta: { title: '联系我们' },
      },
      {
        path: 'development',
        name: 'Development',
        component: () => import('../views/AboutUs/development/index.vue'),
        meta: { title: '发展历程' },
      },
      {
        path: 'organizational',
        name: 'Organizational',
        component: () => import('../views/AboutUs/organizational/index.vue'),
        meta: { title: '组织架构' },
      },
      {
        path: 'shareholder',
        name: 'Shareholder',
        component: () => import('../views/AboutUs/shareholder/index.vue'),
        meta: { title: '股东介绍' },
      },
    ],
  },

  {
    path: '/',
    redirect: '/home',
    meta: { title: '江西瑞京金融资产管理有限公司' },
  },
  // {
  //   path: '*',
  //   name: 'catchAll',
  //   component: () => import('../views/Home/index.vue'),
  // },
];

const router = new VueRouter({
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

export default router;
